.auth {
	&__main {
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: #f7fafc;
	}

	&__form {
		display: flex;
		direction: column;
		align-items: center;
		height: 650px;
		width: 1115px;
		border-radius: 10px;
		margin: 70px 0px;
		overflow: hidden;
		box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.2);

		@media (max-width: $tablet) {
			width: 723px;
		}

		@media (max-width: $mobile) {
			width: 300px;
		}

		&__image {
			display: flex;
			width: 392px;
			height: 100%;
			background: linear-gradient(#3574b3, #04ad95);
			justify-content: center;
			align-items: center;

			@media (max-width: $tablet) {
				display: none;
			}
		}

		&__main {
			display: flex;
			height: 100%;
			width: 723px;
			flex-direction: column;
			align-items: center;
			background: white;
	
			@media (max-width: $mobile) {
				width: 300px;
			}
		}

		&__centered {
			display: flex;
			width: 545px;
			height: 100%;
			flex-direction: column;
			margin-top: 120px;
			align-items: center;

			@media (max-width: $mobile) {
				width: 280px;
				margin-top: 50px;
			}
		}

		&__welcome {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 53px;
			gap: 10px;

			&__text {
				font-family: $fontNunitoSansBold;
				@include normal-font(30px, 41px);
				font-size: 30px;
				font-weight: 700;
				text-align: center;
				margin: 0;
				width: 214px;
				height: 41px;
				top: 370px;
				left: 822px;
				gap: 0px;
				white-space: nowrap;
			}
		}

		&__login {
			&__text {
				font-family: $fontInterLight;
				@include normal-font(16px, 26px);
				font-weight: 400;
				text-align: center;
				color: #545e6a;
				margin: 0px;

				@media (max-width: $mobile) {
					width: 150px;
				}
			}
		}
		
		&__success {
			margin-top: 120px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		&__svg {
			&__image {
				width: 346px;
				height: 303px;
			}
		}

		&__text {
			font-size: 14px;
			font-weight: 400;
			line-height: 16.41px;
			letter-spacing: 0.02em;
			text-align: left;
			margin: 0;

			@media (max-width: $mobile) {
				font-size: 8px;
				font-weight: 200;
			}
		}

		&__input {
			width: 545px;

			@media (max-width: $mobile) {
				width: 280px;
			}
		}
	}
}

.btn {
	&__auth {
		@extend .btn;
		margin: 20px 0px;
		width: 262px;
		height: 56px;
	}
}