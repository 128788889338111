.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    overflow: auto;
    padding: 50px 27px;
    
    &-body {
        max-width: 1170px;
        background: #fff;
        text-align: center;
        margin: 0 auto;
        padding: 63px 50px 80px;
        border-radius: 20px;
        
        h2 {
            @include normal-font(30px, 45px);
            font-family: $fontNunitoSansBold;
            margin-bottom: 20px;
        }
        
        h3 {
            font-family: $fontNunitoSansExtraBold;
            @include normal-font(20px, 28px);
            background: linear-gradient(90deg, $secondaryColor 13.43%, #03B093 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 20px;
        }
        
        p {
            @include normal-font(16px, 22px);
            color: #545E6A;
            margin-bottom: 30px;
        }
        
        img {
            margin-top: 40px;
        }
    }
    
    &__content {
        max-width: 700px;
        margin: 0 auto;
    }
    
    @media (max-width: $tablet) {
        &-body {
            padding: 40px 22px 60px;
            
            h2 {
                @include normal-font(22px, 30px);
            }
            
            h3 {
                @include normal-font(14px, 19px);
                margin-bottom: 18px;
            }
            
            p {
                @include normal-font(14px, 20px);
            }
        }
    }
}
