.fl-right {
    float: right;
}

.fl-left {
    float: left;
}

.center-block {
    margin: 0 auto;
}

.absolute-position {
    position: absolute;
}

.relative-position {
    position: relative;
}

.text-center {
    text-align: center;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    
    li {
        padding: 0;
        margin: 0;
    }
}

.form-list {
    @extend .list-reset;
    
    &.inline {
        > li {
            @include inline-block(top);
        }
    }
}

.centered {
    width: 100%;
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
}
