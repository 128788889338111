.btn {
    color: #fff;
    font-family: $fontInterBold;
    @include inline-block(top);
    background: linear-gradient(270deg, #03B093 0%, $secondaryColor 100%);
    border-radius: 5px;
    padding: 12px 15px;
    text-align: center;
    cursor: pointer;
    border: none;
    @include normal-font(14px, 18px);
    position: relative;
    
    &:hover {
        text-decoration: none;
        background: linear-gradient(270deg, $secondaryColor 0%, #03B093 100%);
    }
    
    &:active {
        background: #03B093;
    }
    
    &.small {
        padding: 11px 15px;
    }
    
    &.outlined {
        padding: 2px;
        
        .button__inner {
            padding: 10px 13px;
            background: #fff;
            border-radius: 4px;
            
            &--text {
                background: linear-gradient(90deg, $secondaryColor -5.81%, #03B093 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    
    &.secondary {
        background: $secondaryColor;
        
        &:hover {
            background: darken($secondaryColor, 10%);
        }
    }
    
    &.w160 {
        max-width: 160px;
        width: 100%;
    }
    
    &.w236 {
        max-width: 236px;
        width: 100%;
    }
}
