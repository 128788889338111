.dashboard {
	&__loan-offers {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		max-width: 990px;
		gap: 20px;

		@media (max-width: $tablet) {
			height: 100%;
			flex-direction: column;
			align-items: center;
		}
	}

	&__action {
		padding-left: 49.5px;
		padding-top: 28px;
		padding-right: 53px;

		&__items {
			display: flex;
			flex-direction: column;
			padding-top: 31px;
			padding-bottom: 41px;
			gap: 23px;

			@media (max-width: $tablet) {
				justify-content: center;
				align-items: center;
			}

			&__item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				box-shadow: 0px 0px 10px 0px #0000001a;
				border-radius: 10px;
				max-width: 582px;
				height: 80px;
				color: #001937;
				font-family: $fontInterBold;
				font-size: 14px;
				font-weight: 700;
				line-height: 16.94px;
				text-align: left;
				padding-right: 60px;
				padding-left: 37px;

				@media (max-width: $tablet) {
					width: 100%;
				}

				@media (max-width: $mobile) {
					flex-direction: column;
					justify-content: center;
				}

				img {
					width: 32px;
					height: 20px;
					margin-right: 25px;
				}

				p {
					color: #545e6a;
					font-family: $fontInterLight;
					font-size: 12px;
					font-weight: 400;
					line-height: 14.52px;
					text-align: right;
					margin-bottom: 0px;
				}
			}
		}

		&__text {
			color: #545e6a;
			font-family: $fontInterLight;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
		}
	}

	&__help {
		padding-top: 28px;
		padding-left: 20px;

		&__head {
			color: #001937;
			font-family: $fontNunitoSansBold;
			font-size: 20px;
			font-weight: 700;
			line-height: 22px;
			text-align: left;
		}

		&__text {
			padding-top: 9px;
			display: flex;
			flex-direction: column;
			gap: 11px;

			p {
				color: #545e6a;
				font-family: $fontInterLight;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				margin-bottom: 0px;
			}
		}
	}

	&__offers {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		min-height: 270px;
		gap: 25px;

		&.padding {
			padding-top: 49px;
		}

		@media (max-width: $mobile) {
			padding-bottom: 20px;
			overflow: hidden;
		}

		&__request {
			width: 90%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			padding-bottom: 60px;
		}

		&__hr {
			width: 80%;
			border-top: 1px;
			border-image: linear-gradient(90deg, #3870b5 -1.63%, #03b093 99.73%) 1;
		}

		&__head {
			color: #001937;
			font-family: $fontNunitoSansBold;
			font-size: 24px;
			font-weight: 700;
			line-height: 32.74px;
			text-align: center;

			@media (max-width: $mobile) {
				width: 80%;
			}
		}

		&__text {
			width: 80%;
			color: #545e6a;
			font-family: $fontInterLight;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			text-align: center;

			p {
				font-family: $fontInterRegular;
				font-weight: 600;
				color: #001937;
			}
		}

		&__requisites {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 300px;
			gap: 12px;

			@media (max-width: $mobile) {
				width: 90%;
				align-items: center;
			}

			&__item {
				display: flex;
				color: #3870b5;
				gap: 12px;
				font-family: $fontInterLight;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				text-align: left;

				img {
					width: 24px;
					height: 24px;
				}
			}
		}

		&__requisites2 {
			@extend .dashboard__offers__requisites;
			align-items: center;
			padding-right: 25px;
		}
	}

	&__form {
		display: flex;
		flex-direction: row;
		width: 612px;
		height: 105px;
		margin-left: 58px;
		margin-bottom: 57px;
		justify-content: space-between;
		position: relative;
		overflow: hidden;

		&__stage {
			display: flex;
			flex-direction: row;

			@media (max-width: $mobile) {
				flex-direction: column;
			}
		}

		&.step-6 {
			width: 355px;
			@media (max-width: $mobile) {
				height: 300px;
			}
		}

		&.step-7 {
			width: 480px;
			@media (max-width: $mobile) {
				height: 400px;
			}
		}

		@media (max-width: $mobile) {
			flex-direction: column;
			height: 500px;
			width: 250px;
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 94px;
			position: relative;
			gap: 15px;
			z-index: 3;

			@media (max-width: $mobile) {
				flex-direction: row;
				width: 220px;
			}

			&__text {
				color: #545e6a;
				font-family: $fontInterLight;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.94px;
				text-align: center;

				@media (max-width: $mobile) {
					text-align: left;
				}
			}

			&__circle {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				border: double 1px transparent;
				border-radius: 80px;
				background-image: linear-gradient(#edf6fd, #edf6fd),
					radial-gradient(circle at top left, #3870b5 0%, #03b093 100%);
				background-origin: border-box;
				background-clip: content-box, border-box;
				height: 48.5px;
				width: 48.5px;

				svg {
					width: 19px;
					height: 25px;
				}
			}

			&.active {
				.dashboard__form__item__circle {
					background: linear-gradient(90deg, #3870b5 0%, #03b093 100%);
					border: none;
				}
			}

			&.hidden {
				display: none;
			}
		}

		&::after,
		&::before {
			content: '';
			height: 1px;
			position: absolute;
			left: 45px;
			right: 25px;
			top: 23px;
			@media (max-width: $mobile) {
				width: 1px;
				height: 100%;
				top: 0px;
				left: 24px;
			}
		}

		&:after {
			background: $secondaryColor;
			z-index: 1;
		}

		&:before {
			background: #abbed4;
			z-index: 2;
		}

		&.step-1 {
			&:after {
				display: none;
			}
		}

		&.step-2 {
			&:before {
				left: 25%;
				@media (max-width: $mobile) {
					left: 24px;
					top: 25%;
				}
			}
		}

		&.step-3 {
			&:before {
				left: 50%;
				@media (max-width: $mobile) {
					left: 24px;
					top: 50%;
				}
			}
		}

		&.step-4 {
			&:before {
				left: 75%;
				@media (max-width: $mobile) {
					left: 24px;
					top: 75%;
				}
			}
		}

		&.step-5 {
			&:before {
				left: 100%;
				@media (max-width: $mobile) {
					display: none;
				}
			}
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&.hidden {
			display: none;
		}

		@media (max-width: $tablet) {
			&.help {
				width: 100%;
			}
		}

		&__loan {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 55px;
			padding-bottom: 19px;

			@media (max-width: $mobile) {
				flex-direction: column;
				gap: 10px;
			}

			&__text {
				color: #545e6a;
				font-family: $fontInterLight;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				max-width: 550px;
			}

			&__text2 {
				color: #545e6a;
				font-family: $fontInterLight;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				max-width: 401px;
			}

			&__image {
				width: 327px;
				height: 185px;
			}
		}

		&__content {
			@extend .card;

			max-width: 990px;

			margin-bottom: 88px !important;

			&.help {
				max-width: 240px;
				min-width: 240px;
				height: 235px;

				@media (max-width: $tablet) {
					max-width: 100%;
					min-width: 100%;
				}
			}

			h3 {
				font-family: $fontNunitoSansBold !important;
				font-size: 24px !important;
				font-weight: 700 !important;
				line-height: 32.74px !important;
				text-align: left !important;
			}
		}
	}

	&__main {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;
		background-color: #f7fafc;

		&__content {
			max-width: 1230px;
			width: 100%;
			margin: 0 auto;
			padding: 0 15px;
		}
	}

	&__welcome {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 70px;

		&__content {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 20px;
		}

		&__head {
			color: #001937;
			font-family: $fontNunitoSansBold;
			font-size: 30px;
			font-weight: 700;
			line-height: 40.92px;
			text-align: left;
		}

		&__text {
			color: #545e6a;
			font-family: $fontInterLight;
			font-size: 16px;
			font-weight: 400;
			line-height: 26px;
			text-align: left;
		}
	}

	&__footer {
		&__main {
			display: flex;
			max-width: 1230px;
			width: 100%;
			margin: 0 auto;
			padding: 0 15px;
			flex-direction: column;
			align-items: center;
			justify-items: center;
			margin-top: auto;
			padding-bottom: 39px;
		}

		&__content {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 10px;
		}

		&__hr {
			display: flex;
			width: 100%;
			color: #a9b9cd;
			border: 0;
			border-top: 1px solid currentColor;
		}

		&__text {
			font-family: $fontInterLight;
			font-size: 10px;
			font-weight: 400;
			line-height: 10px;
			text-align: left;
			color: #545e6a;
		}
	}
}

.btn {
	&__dashboard {
		@extend .btn;
		display: flex;
		width: 257px;
		height: 56px;
		align-items: center;
		justify-content: center;
		margin-top: 27px;
		margin-left: 20px;
		margin-right: 10px;
	}
}

