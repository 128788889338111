.progress {
    @include flexbox();
    @include justify-content(space-between);
    text-align: center;
    margin-bottom: 18px;
    position: relative;
    
    &__item {
        color: #ABBED4;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        font-family: $fontInterBold;
        
        &--number {
            width: 28px;
            height: 28px;
            background: #ABBED4;
            text-align: center;
            color: #fff;
            @include normal-font(16px, 19px);
            border-radius: 100%;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            margin-bottom: 7px;
            position: relative;
            z-index: 3;
        }
        
        &--title {
            @include normal-font(14px, 20px);
        }
        
        &--text {
            @include normal-font(10px, 20px);
        }
        
        &.active {
            color: $secondaryColor;
            
            .progress__item--number {
                background: $secondaryColor;
            }
        }
    }
    
    &:after,
    &:before {
        content: "";
        height: 2px;
        position: absolute;
        left: 50px;
        right: 50px;
        top: 13px;
    }
    
    &:after {
        background: $secondaryColor;
        z-index: 1;
    }
    
    &:before {
        background: #ABBED4;
        z-index: 2;
    }
    
    &.step-4 {
        &:before {
            display: none;
        }
    }
    
    &.step-2 {
        &:before {
            left: 33%;
        }
    }
    
    &.step-3 {
        &:before {
            left: 66%;
        }
    }
    
    @media (max-width: $tablet) {
        margin-bottom: 40px;
        
        &__item {
            &--number {
                width: 17px;
                height: 17px;
                font-size: 10px;
                margin-bottom: 4px;
            }
            
            &--title {
                @include normal-font(8px, normal);
            }
            
            &--text {
                @include normal-font(6px, 10px);
            }
        }
        
        &:after,
        &:before {
            height: 1.2px;
            left: 30px;
            right: 30px;
            top: 9px;
        }
    }
}
