.loader {
    position: relative;
    width: 78px;
    height: 78px;
    margin: 0 auto 6px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    
    &__value {
        position: absolute;
        z-index: 2;
        width: 50px;
        height: 50px;
        background: #EDF5FF;
        border-radius: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        
        &--percent {
            background: linear-gradient(90deg, $secondaryColor 0%, #03B093 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include normal-font(16px, 22px);
            font-family: $fontInterBold;
        }
    }
}

.uppy-Root {
    &.uploaded {
        .uppy-DragDrop-container {
            min-height: 222px;
            
            @media (max-width: $tablet) {
                min-height: 162px;
            }
        }
    }
}

.uppy-DragDrop-container {
    box-shadow: none !important;
    
    .uppy-DragDrop-note {
        @include normal-font(16px, 19px);
        color: $secondaryColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        
        @media (max-width: $tablet) {
            @include normal-font(14px, 17px);
        }
    }
    
    .finale-icon {
        margin-bottom: 46px;
        
        @media (max-width: $tablet) {
            margin-bottom: 13px;
        }
    }
    
    .uppy {
        &__loader {
            width: 104px;
            height: 104px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            margin: 0 auto 7px;
            position: relative;
            
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            
            &--text {
                background: linear-gradient(90deg, #3870B5 0%, #03B093 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 7px;
                @include normal-font(16px, 22px);
                font-family: $fontInterBold;
            }
        }
        
        &__text {
            color: $secondaryColor;
            @include normal-font(14px, 20px);
            font-family: $fontInterBold;
            position: relative;
            padding-left: 25px;
            padding-right: 25px;;
            
            &--inner {
                display: block;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            &--delete {
                background: none;
                cursor: pointer;
                border: none;
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
            
            &:before {
                content: "";
                width: 20px;
                height: 20px;
                background: url("../../../images/file.svg") no-repeat;
                margin-right: 5px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        
        &__list {
            @include flexbox();
            
            @include flex-direction(column);

            .uppy__text ~ .uppy__text {
                margin-top: 4px;
            }
            
            @media (max-width: $tablet) {
                display: block;
                
                .uppy__text {
                    max-width: none !important;
                    
                    + .uppy__text {
                        margin-left: 0 !important;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    
    .uppy-DragDrop-inner {
        padding: 0 10px;
        max-width: 100%;
        
        .uppy-c-icon {
            display: none;
        }
        
        .uppy-DragDrop-label {
            margin-bottom: 0;
            
            p {
                @include normal-font(20px, 26px);
                font-family: $fontInterBold;
                
                &:before {
                    content: "";
                    display: block;
                    width: 25px;
                    height: 20px;
                    margin: 0 auto 13px;
                    background: url("../../../images/cloud.svg") center bottom no-repeat;
                }
                
                @media (max-width: $tablet) {
                    @include normal-font(18px, 24px);
                    max-width: 250px;
                }
            }
            
            .uppy-DragDrop-browse {
                display: block;
                max-width: 236px;
                width: 100%;
                background: $secondaryColor;
                border-radius: 5px;
                margin: 13px auto 0;
                padding: 13px;
                color: #fff;
                @include normal-font(14px, 17px);
                @include transition(background 0.2s ease-in-out);
                font-family: $fontInterBold;
                text-transform: capitalize;
                
                &:hover {
                    background: darken($secondaryColor, 10%);
                }
            }
        }
        
        @media (max-width: $tablet) {
            max-width: 90%;
        }
    }
    
    &.uppy-DragDrop--isDragDropSupported {
        border: 2px dashed $secondaryColor;
        border-radius: 10px;
        padding: 16px 16px 20px;
        background: #EDF5FF;
        
        @media (max-width: ($tablet)) {
            padding: 20px;
        }
    }
}
