select,
textarea,
input {
    width: 100%;
    height: 55px;
    padding: 10px 25px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    color: #4C595F;
    font-family: $fontInterRegular;
    background: #fff;
    
    @include placeholder() {
        color: #C7C7C7;
    }
    
    &:active,
    &:focus {
        &:not(.invalid) {
            + label {
                color: $secondaryColor;
            }

            outline: 2px solid $secondaryColor;
        }

        &.invalid {
            + label {
                color: red;
            }

            outline: 2px solid red;
        }
    }

    &.invalid {
        border-color: red;
    }
    
    @media (max-width: $tablet) {
        height: 50px;
        padding: 10px 23px;
    }
}

input[type="radio"],
input[type="checkbox"] {
    width: 10px;
    height: 10px;
}

textarea {
    height: 84px;
}

.form-error {
    font-size: 14px;
    color: red;
    margin-top: 5px;
}

.form-group {
    margin-bottom: 15px;
    padding-top: 9px;
    position: relative;
    
    label {
        @include normal-font(16px, 19px);
        font-family: $fontInterBold;
        color: $mainFontColor;
        position: absolute;
        background: #fff;
        padding: 0 10px;
        left: 15px;
        top: 0;
        z-index: 2;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    div {
        &:focus-within {
            + label {
                color: $secondaryColor;
            }
        }
    }

    .invalid.rs-picker {
        .rs-picker-toggle-active {
            outline: 2px solid red;
        }

        .rs-picker-toggle {
            border: 1px solid red;
        }
    }
    
    .rs-picker {
        display: block;
        position: relative;
        z-index: 1;
        
        &-toggle {
            &-active {
                outline: 2px solid $secondaryColor;
                border-color: #DADADA !important;
            }
            
            &-value,
            &-placeholder {
                display: none;
            }
            
            &-textbox {
                @include opacity(1);
                outline: none !important;
                padding-left: 20px;
                
                @include placeholder() {
                    color: #4C595F;
                }
            }
            
            &-caret {
                top: 15px !important;
            }
            
            &:hover {
                border-color: #DADADA !important;
            }
        }
        
        .rs-picker-toggle-textbox {
            position: static;
        }
    }
    
    .form-control {
        &.PhoneInput {
            display: block;
            position: relative;
            
            input {
                padding-left: 65px;
            }
            
            .PhoneInputCountry {
                position: absolute;
                top: 19px;
                left: 20px;
            }
        }
    }
    
    @media (max-width: $tablet) {
        label {
            @include normal-font(14px, 17px);
            padding: 0 7px;
        }
    }
}

select {
    option {
        &[value="placeholder"] {
            color: red;
        }
    }
}
