.qr {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    text-align: center;
    margin-bottom: 7px;
    
    &__code {
        margin-bottom: 20px;
    }
    
    &__details {
        @include normal-font(14px, 21px);
    }
    
    @media (max-width: $tablet) {
        &__details {
            @include normal-font(12px, 19px);
        }
    }
}
