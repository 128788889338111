.main {
    &__content {
        .progress {
            max-width: 840px;
        }
        
        .information-wrapper {
            max-width: 310px;
            @include flex(none);
        }
        
        &--wrapper {
            @include flexbox();
            @include align-items(flex-start);
        }
        
        &--form {
            max-width: 840px;
            width: 100%;
            
            &.step-2,
            &.step-1 {
                border-radius: 20px;
                border: 1px solid #C2CEDD;
                background: #FFF;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                
                .card {
                    border-radius: 0;
                    border: none;
                    background: none;
                    box-shadow: none;
                    margin-bottom: 0;
                }
            }
        }
        
        @media (max-width: $tablet) {
            &--form {
                max-width: none;
                
                &.step-1,
                &.step-2 {
                    .card__inner {
                        padding-bottom: 21px;
                    }
                }
                
                &.step-4,
                &.step-3 {
                    .card__actions {
                        padding-bottom: 5px;
                        padding-top: 30px;
                    }
                }
            }
            
            &--wrapper {
                @include flex-direction(column-reverse);
            }
            
            .information-wrapper {
                max-width: none;
            }
        }
    }
}

.page-intro {
    text-align: center;
    padding: 80px 0 160px;
    background: url("../../../images/page-intro-bg.svg") center bottom no-repeat;
    background-size: cover;
    margin-bottom: 47px;
    
    &__title {
        color: #fff;
        font-family: $fontNunitoSansBold;
        @include normal-font(48px, 56px);
    }
}

.form-intro {
    .progress {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
    }
    
    &__title {
        max-width: 428px;
        @include normal-font(36px, 44px);
        font-family: $fontInterBold;
        margin-bottom: 20px;
        
        &.step-3 {
            max-width: 500px;
        }
        
        &.step-4 {
            max-width: 375px;
        }
    }
    
    &__text {
        max-width: 510px;
        color: #545E6A;
        @include normal-font(16px, 26px);
        margin-bottom: 40px;
    }
    
    @media (max-width: $tablet) {
        &__title {
            max-width: none !important;
            text-align: center;
            margin-bottom: 15px;
            @include normal-font(24px, 29px);
        }
        
        &__text {
            text-align: center;
            @include normal-font(12px, 20px);
            margin-bottom: 30px;
            max-width: none;
        }
    }
}

.card {
    border-radius: 20px;
    border: 1px solid #C2CEDD;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
    margin-bottom: 40px;
    
    h3 {
        color: #fff;
        text-align: center;
        margin-bottom: 0;
        @include normal-font(24px, 29px);
        font-family: $fontInterBold;
        padding: 15px;
        background: linear-gradient(90deg, $secondaryColor 0%, #03B093 100%);
        border-radius: 20px 20px 0 0;
    }
    
    .help-text {
        @include normal-font(14px, 22px);
        margin-top: 10px;
    }
    
    .form-error {
        margin-bottom: 10px;
    }
    
    &__label {
        color: $secondaryColor;
        @include normal-font(16px, 22px);
        margin-bottom: 18px;
        font-family: $fontInterBold;
    }
    
    &__text {
        color: #545E6A;
        @include normal-font(20px, 22px);
        font-family: $fontInterLight;
    }
    
    &__files {
        overflow: hidden;
        text-overflow: ellipsis;
        color: #545E6A;
        @include normal-font(18px, 22px);
        font-family: $fontInterLight;
        padding-left: 28px;
        position: relative;
        
        &:before {
            content: "";
            width: 20px;
            height: 20px;
            background: url("../../../images/file.svg") no-repeat;
            position: absolute;
            top: 2px;
            left: 0;
        }
    }
    
    &__sign {
        border-radius: 10px;
        border: 2px dashed $secondaryColor;
        background: #EDF5FF;
        height: 162px;
        color: #B3C2D3;
        font-size: 50px;
        text-align: center;
        margin-bottom: 50px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }
    
    &__inner {
        padding: 40px 50px 50px;
        
        .text-center {
            .actions__buttons--or,
            .btn {
                margin-top: 30px !important;
            }
        }
        
        .qr-code,
        .qr {
            + .text-center {
                .actions__buttons--or,
                .btn {
                    margin-top: 7px !important;
                }
            }
        }
        
        &.business {
            .actions {
                &__buttons {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);
                    position: relative;
                    padding-bottom: 30px;
                    
                    .btn {
                        position: relative;
                    }
                    
                    &--or {
                        @include normal-font(16px, 19px);
                        margin: 0 40px;
                    }
                    
                    &--text {
                        @include normal-font(14px, 22px);
                        position: absolute;
                        top: 100%;
                        margin-top: 10px;
                        color: $mainFontColor;
                        text-align: center;
                        font-family: $fontInterRegular;
                        white-space: nowrap;
                        left: -78%;
                        @include transform(translateX(50%));
                    }
                }
            }
        }
        
        &.license {
            .actions {
                &__buttons {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);
                    position: relative;
                    
                    .btn {
                        position: relative;
                    }
                    
                    &.padding {
                        padding-bottom: 32px;
                    }
                    
                    &--or {
                        @include normal-font(16px, 19px);
                        margin: 0 40px;
                    }
                    
                    &--text {
                        @include normal-font(14px, 22px);
                        position: absolute;
                        top: 100%;
                        margin-top: 10px;
                        color: $mainFontColor;
                        text-align: center;
                        font-family: $fontInterRegular;
                        white-space: nowrap;
                        left: -78%;
                        @include transform(translateX(50%));
                    }
                }
            }
        }
    }
    
    &__link {
        color: #000;
        text-transform: none;
        @include normal-font(16px, 19px);
        text-decoration: underline;
        
        &:hover {
            text-decoration: none;
        }
    }
    
    &__actions {
        @include flexbox();
        @include justify-content(center);
        padding-bottom: 50px;
        
        .btn {
            max-width: 160px;
            width: 100%;
            
            + .btn {
                margin-left: 50px;
            }
        }
        
        &.space-between {
            @include justify-content(space-between);
            padding-top: 40px;
            
            .btn {
                + .btn {
                    margin-left: 0;
                }
            }
        }
    }
    
    &.final {
        .row {
            margin-bottom: 18px;
        }
        
        .btn {
            margin-top: 32px;
        }
    }
    
    @media (max-width: $tablet) {
        margin-bottom: 30px;
        
        h3 {
            @include normal-font(22px, 27px);
        }
        
        &__inner {
            padding: 27px 23px 36px;
            
            &.license,
            &.business {
                .actions {
                    &__buttons {
                        @include flex-direction(column);
                        padding-bottom: 0;
                        
                        .btn {
                            &.button-with-text {
                                margin-bottom: 0;
                                
                                + .actions__buttons--or {
                                    margin-top: 35px !important;
                                }
                            }
                        }
                        
                        &--text {
                            @include normal-font(12px, 22px);
                            left: -60%;
                        }
                        
                        &--or {
                            @include normal-font(12px, 15px);
                            margin: 10px 0 15px;
                            
                            + .btn {
                                margin-top: 0 !important;
                            }
                        }
                    }
                }
            }
        }
        
        &__actions {
            padding-bottom: 40px;
        }
        
        &__label {
            @include normal-font(14px, 20px);
            margin-bottom: 20px;
        }
        
        &__text {
            @include normal-font(18px, 22px);
            margin-bottom: 20px;
        }
        
        &__sign {
            margin-bottom: 10px;
        }
        
        &__files {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        &__actions {
            padding-left: 23px;
            padding-right: 23px;
            
            .btn {
                + .btn {
                    margin-left: 32px;
                }
            }
        }
        
        &.final {
            .row {
                margin-bottom: 0;
                
                .col {
                    @include flex(50%);
                }
                
                &.items-3 {
                    display: block;
                    
                    .col {
                        width: auto;
                        
                        + .col {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

.bank-info {
    &__title {
        @include normal-font(16px, 19px);
        font-family: $fontInterBold;
        margin-bottom: 7px;
    }
    
    &__text {
        color: #545E6A;
        @include normal-font(14px, 20px);
    }
    
    &__details {
        margin-bottom: 20px;
    }
    
    &__actions {
        &--buttons {
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            
            .btn {
                max-width: 236px;
                width: 100%;
            }
        }
        
        &--or {
            @include normal-font(16px, 19px);
            margin: 0 20px;
        }
    }
    
    @media (max-width: $tablet) {
        &__details {
            text-align: center;
            
            &__title {
                @include normal-font(13px, 16px);
                margin-bottom: 10px;
            }
            
            &__text {
                @include normal-font(10px, 16px);
            }
        }
        
        &__actions {
            &--buttons {
                @include flex-direction(column);
            }
            
            &--or {
                margin: 10px 0;
            }
        }
    }
}

.e-sign {
    &__area {
        height: 162px;
        border-radius: 10px;
        border: 2px dashed $secondaryColor;
        background: #EDF5FF;
        margin-bottom: 10px;
    }
    
    &__text {
        color: #545E6A;
        @include normal-font(8px, 12px);
        margin-bottom: 20px;
    }
    
    &__terms {
        color: $secondaryColor;
        font-family: $fontInterBold;
        @include normal-font(12px, 15px);
        @include flexbox();
        @include align-items(center);
        
        input {
            margin-right: 6px;
        }
    }
    
    @media (max-width: $tablet) {
        &__text {
            @include normal-font(10px, 18px);
        }
    }
}

.information-wrapper {
    margin-left: 50px;
    padding: 23px 23px 30px;
    border-radius: 20px;
    border: 1px solid #C2CEDD;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
    
    &__title {
        font-family: $fontInterBold;
        @include normal-font(23px, 34px);
        color: $mainFontColor;
    }
    
    &__image {
        margin-bottom: 40px;
    }
    
    &__text {
        color: #545E6A;
        @include normal-font(14px, 24px);
    }
    
    @media (max-width: $tablet) {
        margin-left: 0;
        margin-bottom: 30px;
        
        &__title {
            @include normal-font(20px, 26px);
        }
        
        &__image {
            text-align: center;
            margin-bottom: 28px;
        }
        
        &__text {
            @include normal-font(12px, 20px);
        }
    }
}
