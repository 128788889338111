.row {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 0 -19px;
    
    .col {
        @include flex-basis(0);
        @include flex-grow(1);
        max-width: 100%;
        padding: 0 19px;
        
        .row{
            margin: 0 -10px;
            
            .col{
                padding: 0 10px;
            }
        }
    }
    
    &.items-3 {
        .col {
            width: 33.3%;
        }
    }
    
    &.three-in-line {
        .col {
            @include flex(33%);
        }
    }
    
    @media (max-width: $tablet) {
        margin: 0 -14px;
        
        .col {
            padding: 0 14px;
        }
        
        &.mobile-row {
            display: block;
        }
        
        &.three-in-line {
            .col {
                @include flex(50%);
            }
        }
    }
}
