.react-datepicker-popper {
    z-index: 111;
    background: #fff;
    border: 1px solid #d0d1d3;
    border-radius: 3px;
    box-shadow: 0 0.0625rem 0.25rem rgba(0,0,0,.11), 0 0 0.25rem rgba(18,25,97,.041);
    width: 382px;
    padding: 18px 15px;
    
    @media (max-width: 500px) {
        max-width: 280px;
    }
}

.react-datepicker {
    &__day {
        cursor: pointer;
        height: 40px;
        min-width: 40px;
        text-align: center;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        border-radius: 100%;
        border: 1px solid transparent;
        font-size: 13px;
        color: #2f4054;
        transition: color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out;
        
        &--outside-month,
        &--disabled {
            pointer-events: none;
            color: #686e77;
            background: #f2f3f5;
            border-color: rgba(32,32,46,.079);
            box-shadow: 0 0.125rem 0.125rem rgba(58,58,87,.06);
        }
        
        &--today {
            border-color: #ABBED4;
        }
        
        &--selected {
            color: #fff;
            background: $secondaryColor;
        }
        
        &-names {
            @include flexbox();
            @include justify-content(space-around);
        }
        
        &-name {
            height: 40px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            color: #2f4054;
            font-size: 13px;
            font-family: $fontInterBold;
        }

        &:hover:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month) {
            border-color: #ABBED4;
        }
        
        @media (max-width: 500px) {
            height: 30px;
            min-width: 30px;
            
            &-name {
                height: 30px;
            }
        }
    }
    
    &__header {
        &--actions {
            @include flexbox();
            @include align-items(center);
            border-bottom: 1px solid #bfbfbf;
            padding-bottom: 5px;
            
            button {
                background: none;
                outline: none;
                font-size: 0;
                cursor: pointer;
                border: none;
                height: 30px;
                width: 30px;
                position: relative;
                
                &:before {
                    border-color: #607382;
                    border-style: solid;
                    border-width: 2px 2px 0 0;
                    content: "";
                    display: block;
                    height: 5px;
                    width: 5px;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                
                &.react-datepicker__header--prev {
                    &:before {
                        transform: rotate(225deg);
                    }
                }
                
                &.react-datepicker__header--next {
                    &:before {
                        transform: rotate(45deg);
                    }
                }
                
                &:hover {
                    &:before {
                        border-color: #2f4054;
                    }
                }
            }
            
            &-select {
                @include flex(1);
                text-align: center;
                
                select {
                    height: auto;
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    width: auto;
                    cursor: pointer;
                    outline: none;
                    
                    + select {
                        margin-left: 15px;
                    }
                }
                
                @media (max-width: 500px) {
                    @include flexbox();
                }
            }
        }
    }
    
    &__week {
        @include flexbox();
        @include justify-content(space-around);
        padding: 5px 0;
    }
    
    &__aria-live {
        text-align: center;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: $fontInterBold;
        color: #001937;
        display: none;
    }
    
    &__tab-loop {
        + label {
            color: $secondaryColor;
        }
    }
}
