.header {
	padding: 24px 0;
	background: #fff;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
	backdrop-filter: blur(10px);
	position: sticky;
	top: 0;
	z-index: 5;

	.centered {
		@include flexbox();
		@include align-items(center);
	}

	&__menu-toggle {
		background: none;
		border: none;
		cursor: pointer;
		display: none;
		padding: 0;
		margin-left: auto;
	}

	&__col {
		width: 35%;
	}

	&__mobile-menu {
		width: 100%;
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-evenly);

		.header__col {
			&.large {
				width: 50%;
			}

			&:not(.large) {
				@include flex(1);
			}
		}

		@media (max-width: $tablet) {
			display: block;
			position: fixed;
			width: 200px;
			background: #ffffff;
			right: 0;
			top: 96px;
			@include transition(transform 0.2s ease-in-out);
			@include transform(translateX(200px));
			padding: 20px;
			@include calc('height', '100vh - 90px');
			box-shadow: 0 8px 10px rgba(0, 0, 0, 0.04);

			.header__col {
				width: auto;
			}

			.header__menu {
				display: block;
				margin-bottom: 20px;

				li {
					+ li {
						margin-left: 0;
						margin-top: 15px;
					}
				}
			}

			.header__actions {
				float: none;
				display: block;

				.btn {
					margin-left: 0;
					margin-top: 20px;
				}
			}
		}
	}

	&__menu {
		float: right;
		padding: 0;
		margin: 0;
		list-style: none;
		@include flexbox();
		@include align-items(center);

		li {
			a {
				white-space: nowrap;
				color: $mainFontColor;
				@include normal-font(16px, 19px);
			}

			+ li {
				margin-top: 0;
				margin-left: 30px;
			}
		}
	}

	&__phone {
		color: #3771b5;
		font-family: $fontInterBold;
		@include normal-font(16px, 19px);
		white-space: nowrap;

		img {
			margin-right: 7px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__actions {
		float: right;
		@include flexbox();
		@include align-items(center);

		.btn {
			margin-left: 10px;

			@media (max-width: 1050px) {
				margin-left: 15px;
			}
		}
	}

	@media (max-width: $tablet) {
		padding: 40px 15px 30px;
		min-height: 96px;

		&__menu-toggle {
			display: block;
		}

		&__logo {
			width: 135px;
		}

		&__menu {
			float: left;
		}

		&.show {
			.header__mobile-menu {
				@include transform(translateX(0));
			}
		}
	}
}