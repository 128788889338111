.footer {
    background: #fff;
    
    &__image {
        @include flex(none);
        margin-right: 18px;
    }
    
    &__text {
        p {
            color: #545E6A;
            @include normal-font(10px, 14px);
            margin-bottom: 0;
            
            + p {
                margin-top: 15px;
            }
        }
    }
    
    &__top {
        padding: 110px 0 44px;
        
        .centered {
            @include flexbox();
            @include align-items(center);
        }
    }
    
    &__bottom {
        padding: 17px 0;
        background: linear-gradient(90deg, #3771B5 0%, #03B093 100%);
        @include normal-font(16px, 26px);
        color: #fff;
        text-align: center;
    }
    
    @media (max-width: $tablet) {
        &__image {
            margin-right: 0;
            margin-bottom: 20px;
        }
        
        &__top {
            padding: 50px 27px 40px;
            
            .centered {
                @include flex-direction(column);
                padding: 0;
            }
        }
        
        &__bottom {
            @include normal-font(10px, 12px);
            padding: 14px 0;
        }
    }
}
