// *** Mixins *** //
@import "../mixins/custom";
@import "../mixins/helper";

// *** Variables *** //
@import "variables";

// *** Helpers *** //
@import "helpers";
@import "icons";
@import "typography";

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: $mainFontColor;
    font-family: $fontInterRegular;
}

body {
    background: #F2FFFD;
}

img{
    max-width: 100%;
    vertical-align: top;
}

.wrapper{
    min-height: 100%;
    @include flexbox();
    @include flex-direction(column);
    
    .main{
        @include flex(1);
        padding-top: 50px;
        padding-bottom: 80px;
    }
    
    @media (max-width: $tablet) {
        overflow: hidden;
        
        .main {
            padding-top: 40px;
            padding-bottom: 55px;
        }
    }
}
