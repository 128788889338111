@include font-face('InterLight', '../../../fonts/InterLight');
@include font-face('InterRegular', '../../../fonts/InterRegular');
@include font-face('InterMedium', '../../../fonts/InterMedium');
@include font-face('InterBold', '../../../fonts/InterBold');
@include font-face('NunitoSansBold', '../../../fonts/NunitoSansBold');
@include font-face('NunitoSansExtraBold', '../../../fonts/NunitoSansExtraBold');

// *** Custom fonts *** //
$fontInterLight: "InterLight", sans-serif;
$fontInterRegular: "InterRegular", sans-serif;
$fontInterBold: "InterBold", sans-serif;
$fontInterMedium: "InterMedium", sans-serif;
$fontNunitoSansBold: "NunitoSansBold", sans-serif;
$fontNunitoSansExtraBold: "NunitoSansExtraBold", sans-serif;

//*-------------base variables------------*/
$bodyHeight: 100%;
$borderRadius: 5px;
$centeredWidth: 1345px;
$centeredPadding: 0 15px;

// *** Colors *** //
$mainBgColor: #121513;
$mainFontColor: #001937;
$secondaryColor: #3870B5;
$placeholderColor: #666666;
$successColor: #0BA186;
$warningColor: #F1982F;
$dangerColor: #FD6262;
$themeColor: #00D700;

// *** Tables *** //
$tableWidth: 100%;
$theadBackground: #F8F8F8;
$tdHoverBackground: #F8F8F8;
$zebraBackground: #F2F2F2;

// *** Base font variables *** //
$linkColor: $themeColor;
$baseFontSize: 14px;
$baseLineHeight: 21px;

// *** Headers sizes *** //
$h1FontSize: 48px;
$h2FontSize: 36px;
$h3FontSize: 24px;
$h4FontSize: 18px;
$h5FontSize: 16px;
$h6FontSize: 14px;

$h1LineHeight: 54px;
$h2LineHeight: 42px;
$h3LineHeight: 30px;
$h4LineHeight: 24px;
$h5LineHeight: 22px;
$h6LineHeight: 20px;

$headingMargin: 0 0 24px 0;
$headingColor: $mainFontColor;

// *** List styles *** //
$listMargin: 0 0 24px 0;
$listPadding: 0 0 0 18px;
$listItemFontSize: 18px;
$listItemLineHeight: 28px;

// *** Paragraph styles *** //
$paragraphFontSize: 18px;
$paragraphLineHeight: 28px;
$paragraphMargin: 0 0 16px 0;
$paragraphColor: $mainFontColor;
$textIndent: 25px;

// *** Label styles *** //
$labelFocusedFontSize: 16px;
$inputDefaultFontSize: 18px;

// *** Media queries *** //
$tablet: 991px;
$mobile: 768px;
