@import "variables";

.list {
    @extend .list-reset;
    
    li {
        margin: 0 0 5px 0;
    }
}

.small-text {
    @include normal-font(11px, 12px);
    margin: 0 0 10px 0;
}

a {
    display: inline-block;
    text-decoration: none;
    
    &:focus,
    &:active {
        outline: none;
    }
    
    &:hover {
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: $headingMargin;
}

h1 {
    @include normal-font($h1FontSize, $h1LineHeight);
    
    @media (max-width: 768px) {
        @include normal-font(30px, 39px);
        margin-bottom: 22px;
    }
}

h2 {
    @include normal-font($h2FontSize, $h2LineHeight);
    
    @media (max-width: 768px) {
        @include normal-font(30px, 45px);
        margin-bottom: 22px;
    }
}

h3 {
    @include normal-font($h3FontSize, $h3LineHeight);
    margin-bottom: 16px;
    
    @media (max-width: 768px) {
        @include normal-font(30px, 45px);
        margin-bottom: 14px;
    }
}

h4 {
    @include normal-font($h4FontSize, $h4LineHeight);
    margin-bottom: 16px;
    
    @media (max-width: 768px) {
        @include normal-font(26px, 39px);
        margin-bottom: 14px;
    }
}

h5 {
    @include normal-font($h5FontSize, $h5LineHeight);
    
    @media (max-width: 768px) {
        @include normal-font(22px, 33px);
        margin-bottom: 22px;
    }
}

h6 {
    @include normal-font($h6FontSize, $h6LineHeight);
    margin-bottom: 16px;
}

p {
    @include normal-font($paragraphFontSize, $paragraphLineHeight);
    margin: $paragraphMargin;
    
    @media (max-width: 768px) {
        @include normal-font(16px, 25px);
    }
}

ul,
ol {
    padding: $listPadding;
    margin: $listMargin;
    
    li {
        color: #BFC1BF;
        @include normal-font($listItemFontSize, $listItemLineHeight);
        
        + li {
            margin-top: 16px;
        }
    }
}
